<template>
  <div>
    <div class="card card-custom gutter-b">
      <!-- <div class="card-body">POB {{ value }} - {{ data }}</div> -->
      <v-btn
        color="primary"
        elevation="2"
        small
        class="ml-auto"
        type="button"
        @click="onClickCreate()"
      >
        Tambah {{ this.title }}
      </v-btn>

      <v-data-table :headers="headers" :items="data" :items-per-page="10">
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <a
                href="#"
                class="btn btn-icon btn-light btn-xs mx-1"
                @click="onClickDelete(item)"
                v-on="on"
                v-bind="attrs"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="asset('/media/svg/icons/General/Trash.svg')"
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </template>
            <span>Hapus</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <list-select-pob
        ref="selectpob"
        :on-save="onSave"
        :exclude="value"
      ></list-select-pob>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ListSelectPob from "./ListSelectPob.vue";
import { asset } from "@/core/utils/";

export default {
  created() {
    this.calculate();
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      data: [],
      title: "Perusahaan Otobus",
      loading: false,
      headers: [
        { text: "Nama Perusahaan", value: "nama" },
        { text: "Alamat", value: "alamat" },
        {
          text: "Tindakan",
          value: "actions",
          align: "center",
          sortable: false,
          width: 100,
        },
      ],
    };
  },
  methods: {
    onClickDelete(val) {
      const idx = this.value.indexOf(val.id);
      if (idx != -1) {
        this.value.splice(idx, 1);
        this.$emit("input", this.value);
        this.calculate();
      }
    },
    onSave(val) {
      if (val && val.length > 0) {
        if (!this.value) {
          this.value = [];
        }
        this.value = [...this.value, ...val];
        this.$emit("input", this.value);
        this.calculate();
      }
    },
    async calculate() {
      if (this.value == null || this.value.length <= 0) {
        this.data = [];
        return;
      }

      this.loading = true;
      const params = {
        filter: [
          {
            column: "id",
            operator: "in",
            value: this.value,
          },
        ],
        columns: [
          { data: "id", searchable: false },
          { data: "nama", searchable: true },
          { data: "no_izin", searchable: true },
          { data: "alamat", searchable: true },
        ],
        perPage: 20,
      };

      const { data } = await ApiService.post("pob/data", params);
      this.data = data.data;
      this.loading = false;
    },
    onClickCreate() {
      this.$refs.selectpob.open(this.value);
    },
    asset,
  },
  watch: {
    value() {
      this.calculate();
    },
  },
  components: {
    ListSelectPob,
  },
};
</script>
