<template>
  <v-dialog v-model="opened" max-width="1200" persistent>
    <v-card>
      <v-card-title class="headline">
        Daftar Perusahaan Otobus
        <v-btn icon @click="close()" class="ml-auto">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <datatable
          ref="datatable"
          module="pob"
          :title="title"
          :url="url"
          :columns="columns"
          disable-create
          disable-action
          show-select
          v-model="selected"
          :filter="filter"
        >
          <template v-slot:action>
            <v-btn
              type="button"
              color="primary"
              depressed
              small
              class="ml-auto mt-5 mr-2"
              :loading="loading"
              :disabled="loading"
              @click.prevent="save()"
            >
              <v-icon small left>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template>
        </datatable>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Datatable from "@/view/component/Datatable.vue";

export default {
  props: {
    onSave: {
      type: Function,
      default: function() {},
    },
  },
  data() {
    return {
      title: "Perusahaan Otobus",
      url: "pob/data",
      selected: [],
      opened: false,
      columns: [
        {
          value: "id",
        },
        {
          text: "Nama",
          value: "nama",
          sortable: true,
          searchable: true,
        },
        {
          text: "No. Izin",
          value: "no_izin",
          sortable: true,
          searchable: true,
        },
        {
          text: "Alamat",
          value: "alamat",
          sortable: true,
          searchable: true,
          width: 400,
        },
      ],
      filter: null,
    };
  },
  components: {
    Datatable,
  },
  methods: {
    open(val) {
      this.opened = true;
      if (val) {
        this.filter = [
          {
            column: "id",
            value: val,
            operator: "notIn",
          },
        ];
        // this.$refs.datatable.getData();
      }
    },
    close() {
      this.opened = false;
      this.selected = [];
      this.filter = null;
    },
    save() {
      if (this.selected.length > 0) {
        let listId = [];
        this.selected.forEach((v) => listId.push(v.id));
        this.onSave(listId);
      } else {
        this.onSave(null);
      }
      this.close();
    },
  },
};
</script>
